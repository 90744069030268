import {Bar, DesktopOnly, Layout, MobileOnly, Text} from '@layerzerolabs/ui-kit';
import {Box, Stack} from '@mui/system';
import Image from 'next/image';
import Link from 'next/link';
import Lottie from 'react-lottie-player';

import HomeAnimation from '../animations/home-animation.json';
import {Button as AptosButton} from '../components/Button';
import {AppFooter, AppHeader} from '../components/Layout';

const SupportedChains = () => (
  <>
    <Text variant='p3' sx={{mb: 1, display: 'block'}}>
      Supported Chains:
    </Text>
    <div>
      {['ethereum', 'matic', 'arbitrum', 'avalanche', 'bnb', 'optimism', 'aptos'].map((title) => (
        <Box key={title} sx={{mr: 1.5, display: 'inline-block'}}>
          <Image src={`/static/homepage-networks/${title}.svg`} height={32} width={32} />
        </Box>
      ))}
    </div>
  </>
);

export const HomePage = () => {
  return (
    <Layout header={<AppHeader />} footer={<AppFooter />}>
      <DesktopOnly
        display='flex'
        sx={{
          flex: 1,
          maxWidth: 1440,
          width: '100%',
          margin: '40px auto',
          padding: '0 48px 0',
        }}
      >
        <Stack direction='row' alignItems='stretch' sx={{flex: 1}}>
          <Stack sx={{pt: 16}}>
            <Text variant='h1' sx={{fontSize: '68px', lineHeight: '96%', letterSpacing: '-0.06em'}}>
              Move freely between Aptos and the world.
            </Text>

            <Text
              variant='p2'
              sx={{mt: 3, maxWidth: '546px', display: 'block'}}
              color='text.secondary'
            >
              The LayerZero Aptos bridge enables seamless asset transfers between Aptos and the
              world of blockchains. Connect your source and destination wallets to get started.
            </Text>

            <Link href='/bridge'>
              <AptosButton variant='primary' sx={{marginTop: {xs: 3, md: 5}, width: 318}}>
                GO TO TRANSFER
              </AptosButton>
            </Link>

            <Box sx={{mt: 'auto'}}>
              <SupportedChains />
            </Box>
          </Stack>

          <Stack sx={{flexShrink: 0}}>
            <Lottie loop animationData={HomeAnimation} play />
          </Stack>
        </Stack>
      </DesktopOnly>

      <MobileOnly>
        <Stack sx={{mt: 5, padding: 2}}>
          <Text variant='h1' sx={{lineHeight: '96%', letterSpacing: '-0.06em'}}>
            Move freely between Aptos and the world.
          </Text>
          <Text
            variant='p2'
            sx={{mt: 3, maxWidth: '546px', display: 'block'}}
            color='text.secondary'
          >
            The LayerZero Aptos bridge enables seamless asset transfers between Aptos and the world
            of blockchains. Connect your source and destination wallets to get started.
          </Text>
          <Link href='/bridge'>
            <AptosButton variant='primary' sx={{marginTop: 2}}>
              GO TO TRANSFER
            </AptosButton>
          </Link>
          <Box sx={{my: 5}}>
            <Lottie loop animationData={HomeAnimation} play />
          </Box>

          <SupportedChains />
        </Stack>
      </MobileOnly>

      <Bar sx={{mt: 7, justifyContent: 'space-between'}}>
        <Bar.Section>
          <Link href='https://layerzero.network/' target='_blank'>
            <Text sx={{cursor: 'pointer', mr: 4}} variant='p3' color='text.secondary'>
              Layerzero.network
            </Text>
          </Link>
          <Link href='https://aptosfoundation.org' target='_blank'>
            <Text sx={{cursor: 'pointer'}} variant='p3' color='text.secondary'>
              aptosfoundation.org
            </Text>
          </Link>
        </Bar.Section>
        <Bar.Section>
          <Image
            src='/static/powered-by-layerzero.svg'
            width={158}
            height={20}
            alt='Powered by LayerZero'
          />
        </Bar.Section>
      </Bar>
    </Layout>
  );
};

export default HomePage;
